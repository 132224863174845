import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
//import Gallery from "@browniebroke/gatsby-image-gallery";
// import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Layout from '../components/layout'
import marked from 'marked'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [pageData] = get(this, 'props.data.allContentfulHomepage.edges')
    const [banner] = get(this, 'props.data.allContentfulBanner.edges')
    let images = this.props.data.images.edges.map(
      ({ node }) => node.childImageSharp
    )

    images.forEach((element) => {
      element.thumbAlt = 'Aoga Amata Newtown'
    })

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet
            title={`Home - ${siteTitle}`}
            meta={[
              {
                name: 'description',
                content:
                  'Welcome to Aoga Amata Newtown website - Daycare in Newtown, Wellington',
              },
              { name: 'keywords', content: 'daycare, wellington, newtown' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Hero data={banner.node} />
          <div className="wrapper">
            <h2 className="section-headline">
              <Link to="/contact-us">Spaces available now</Link>
            </h2>
            <div
              className="section-content"
              dangerouslySetInnerHTML={{
                __html: marked(pageData.node.content.content),
              }}
            ></div>
            {/*<div className="section-content">
              <Gallery images={images} />
            </div>*/}
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery($locale: String) {
    allContentfulBanner(
      filter: {
        contentful_id: { eq: "2HfcRvUzjVr07IpyIfT4MP" }
        node_locale: { eq: $locale }
      }
    ) {
      edges {
        node {
          title
          buttonText
          buttonLink
          heroImage: image {
            fluid(
              maxHeight: 500
              resizingBehavior: PAD
              background: "rgb:FFFFFF"
            ) {
              src
              srcSet
              base64
            }
          }
        }
      }
    }
    allContentfulHomepage {
      edges {
        node {
          title
          content {
            content
          }
        }
      }
    }
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
